<div class="Container">
  <div class="Title">PLEASE READ</div>

  <div class="Subtitle">Final Agreement Information</div>

  <button class="aBtn asSmall atDark" data-icon="info"
          on:click="{showTutorial}">Click Here</button>
</div>


<script>
  import { onMount } from 'svelte';
  import { Dialog } from 'root/v-app/rbServices';
  import TutorialDialog from './FinalAgreementEmailNotificationTutorialDialog.vue';
  import { currentUser } from 'root/angular-injector-provider';

  const TUTORIAL_NAME = 'FinalAgreementEmailNotification';

  let
    user = {},
    disabled = false;

  onMount(async () => {
    try {
      user = await currentUser().get();
      disabled = user.tutorials.indexOf(TUTORIAL_NAME) === -1;
      if (!disabled)
        await showTutorial();
    } catch (ignored) {
      // ignored
    }
  });

  async function showTutorial(){
    try {
      const disableTutorial = await Dialog.show(TutorialDialog, {user: user.firstName, disabled});
      if(!disabled && disableTutorial){
        await currentUser().disableTutorial(TUTORIAL_NAME);
        disabled = true;
      }
    } catch (ignored) {
      // ignored
    }
  }
</script>


<style lang="stylus">.Container {
  display: block;
  background: rgba(38,50,56,0.2);
  border: 0;
  border-radius: 0 0 5px 5px;
  width: 270px;
  height: 90px;
  text-align: center;
}
.Title {
  font-weight: bold;
  font-size: 13px;
  text-transform: uppercase;
  color: #fff;
  line-height: 16px;
  padding-top: 10px;
}
.Subtitle {
  font-size: 12px;
  line-height: 16px;
  color: rgba(255,255,255,0.7);
  margin-bottom: 5px;
}
</style>
