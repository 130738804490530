import SvelteNgComponent from 'root/svelte-ng-components/SvelteNgComponent.js';
import Component from './ManageAttachments.svelte';

export default {
  template: '<div></div>',
  bindings: {
    title: '<',
    attachments: '<',
    target: '@',
    targetId: '<',
    addAttachmentFunction: '<',
    removeAttachmentFunction: '<',
    token: '<',
  },
  controller: Ctrl
}

Ctrl.$inject = ['$element'];
function Ctrl($element){
  const vm = this;

  Object.assign(vm, SvelteNgComponent(Component, $element[0], () => ({
    title: vm.title,
    target: vm.target,
    targetId:  vm.targetId,
    attachments:  vm.attachments,
    addAttachmentFunction: vm.addAttachmentFunction,
    removeAttachmentFunction: vm.removeAttachmentFunction,
    token: vm.token
  })));
}
