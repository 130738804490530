import { progress } from './progress.store';
import { mainAPI } from 'root/angular-injector-provider';

export default async function uploadAttachment(file, finalizeAddAttachmentFunction, target, targetId, token) {
  updateProgress(0);
  const fileReaderResult = await readFile(file, p => updateProgress(Math.round(p/100*5)));
  updateProgress(5);
  const attachment = await saveAttachmentData(file, target, targetId, token);
  updateProgress(10);
  await uploadAttachmentToBucket(fileReaderResult, attachment, p => updateProgress(Math.round(p / 100 * 85) + 10));
  updateProgress(95);
  await finalizeAddAttachmentFunction(attachment.id, token);
  updateProgress(100);
}

function updateProgress(val){
  progress.set(val);
}

function readFile(file, progressCallback){
  return new Promise(((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = e => resolve(e.target.result);
    reader.onerror = e => reject(e);
    reader.onabort = e => reject(e);
    reader.onprogress = e => progressCallback( e.lengthComputable ? Math.round((e.loaded / e.total) * 100 ) : 0);

    reader.readAsArrayBuffer(file);
  }));
}

async function saveAttachmentData(file, target, targetId, token){
  const attachment = {
      name: file.name,
      contentType: file.type,
      size: file.size,
      documentType: target,
      documentId: targetId,
  };

  const creationResult = await mainAPI().createAttachment(attachment, token);
  return creationResult.data;
}

function uploadAttachmentToBucket(fileReaderResult, attachment, progressCallback){
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.onload = e => resolve(e);
    xhr.onerror = e => reject(e);
    xhr.onabort = e => reject(e);
    xhr.upload.onprogress = e => progressCallback( e.lengthComputable ? Math.round((e.loaded / e.total) * 100 ) : 0);

    xhr.open('PUT', attachment.signedUrl, true);
    xhr.setRequestHeader('Content-Type', attachment.contentType);
    xhr.send(fileReaderResult);
  })
}
