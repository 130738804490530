import HTML from './bid-response.cmp.html';
import get from 'lodash/get';
import noop from 'lodash/noop';
import {mainAPI, notificationUtils} from 'root/angular-injector-provider';

const
  STANDARD = 'STANDARD',
  RATE_QUICK = 'RATE_QUICK',
  PER_DIEM = 'PER_DIEM';

export default {
  template: HTML,
  bindings: {
    bid: '<',
    options: '<'
  },
  controller: Ctrl,
}

Ctrl.$inject = [];
function Ctrl(){
  const vm = this;
  vm.isStandard = false;
  vm.isRateQuick = false;
  vm.isPerDiem = false;
  vm.attachments = [];

  vm.$onChanges = () => {
    if(vm.bid){
      vm.isStandard = isSubtype(vm.bid, STANDARD);
      vm.isRateQuick = isSubtype(vm.bid, RATE_QUICK);
      vm.isPerDiem = isSubtype(vm.bid, PER_DIEM);

      vm.manageAttachments = {
        title: 'Add attachment to response',
        target: 'HOTEL_RFP_BID_RESPONSE',
        targetId: vm.bid.id,
        addAttachmentFunction: id => {
          notificationUtils().onSave(() => mainAPI().hotelBid.addResponseAttachment(vm.bid.id, {id}))
            .then(r => { vm.attachments = r.data || []; })
            .catch(noop)
        },
        removeAttachmentFunction: id => {
          notificationUtils().onSave(() => mainAPI().hotelBid.removeResponseAttachment(vm.bid.id, id))
            .then(r => { vm.attachments = r.data || []; })
            .catch(noop)
        },
      };

      vm.attachments = vm.bid.questionnaire.responseDraft.attachments || [];
    }
  }
}

function isSubtype( bid, subType ){
  return (get(bid, 'specifications.subType', STANDARD) === subType);
}
