<script context="module">
  import get from 'lodash/get';

  const
    REASONS = [
      '<div>{{property.name}} Overall Rates and Amenity offering not strong enough</div>',
      '<div>We did not get a response back in time to consider your hotel</div>',
      '<div>We did not feel that the {{property.name}} was a good fit for {{buyer.company.name}} at this time</div>',
      '<div>You were not able to include specific amenities with the Fixed rate offered while your competitors were</div>',
      '<div>We have a long history of travel to {{property.address.city}} and changing hotels at this time is not in our best interest</div>',
      '<div>{{property.name}} was just a bit too far from our location to consider</div>',
    ],

    LETTER_TEMPLATE = '{{buyer.contact.company.logo}}<br />' +
      '<br />' +
      '{{supplier.contact.firstName}},<br />' +
      '<br />' +
      'We had several tough choices to make this year in the {{property.address.city}} area. I am sorry to say your hotel did not quite make our final list for the following reasons:<br />' +
      '<br />' +
      '<div class="Reasons">{{REASONS}}</div>'+
      '<br />' +
      '<div class="Message">{{MESSAGE}}</div>' +
      '<br />' +
      'Thank you again for your time and offering.<br />' +
      '<br />' +
      'Sincerely,<br />{{buyer.contact.fullName}}<br />{{buyer.contact.jobTitle}}<br />{{buyer.contact.company.name}}' +
      '',

    REASONS_TEMPLATE_PARSER = bid => REASONS.map(r => parseTemplate(r, bid));

  function parseTemplate(template, data){
    return template.replace(/{{(.*?)}}/g, (i, match) => get(data, match));
  }
</script>

<script>
  import Spinner from 'root/components/atoms/Spinner.svelte';
  import ClosableModal from 'root/services/modals/Closable.modal.svelte';
  import { onMount } from 'svelte';
  import { mainAPI, notificationUtils } from 'root/angular-injector-provider';
  import noop from 'lodash/noop';
  import PreviewDialog from './ViewNoThankYouLetterDialog.svelte';
  import {SvelteDialog} from 'root/v-app/rbServices';

  export let cancel, hide, bidsIds;

  let
    letterTemplate = LETTER_TEMPLATE,
    viewPromise,
    bidData,
    isSingleBid,
    reasons = [],
    selectedReasons = [],
    ownReason = '',
    message = 'We are encouraging you to please bid again next year as we did feel your offering had merit and was a strong candidate.',
    multipleBidsTextSuffix = '';

  $: reasons = bidData ? REASONS_TEMPLATE_PARSER(bidData) : [];
  $: multipleBidsTextSuffix = isSingleBid ? '' : 's';
  $: letterTemplate = updateLetterTemplate(selectedReasons, ownReason, message);

  onMount(() => {
    if(!bidsIds || !bidsIds.length) {
      cancel();
    } else {
      isSingleBid = bidsIds.length === 1;
      viewPromise = loadDataForTheBid(0);
    }
  })

  function loadDataForTheBid(bidIndex){
    if(bidIndex === null) return;

    const bidId = bidsIds[bidIndex];
    return new Promise((resolve, reject) => {
      notificationUtils().onLoad(() => mainAPI().getNoThankYouTemplateData(bidId))
        .then(({data}) => {
          bidData = data;
          resolve();
        })
        .catch(() => reject());
    })
  }

  function toggleSelection(index){
    if(selectedReasons.indexOf(index) === -1){
      selectedReasons = [...selectedReasons, index];
    } else {
      selectedReasons = selectedReasons.filter(r => r !== index);
    }
  }

  function updateLetterTemplate(selectedReasons = [], ownReason, message){
    const reasons = REASONS.filter((v, index) => selectedReasons.indexOf(index) !== -1);
    if(ownReason) reasons.push(secure(ownReason));
    const reasonsHTML = reasons.length ? reasons.reduce((acc,m) => `${acc}\n<div class="Reason">${m}</div>`, '') : '<span class="NoReasons">Reasons go here</span>'
    return LETTER_TEMPLATE.replace(/{{REASONS}}/, reasonsHTML).replace(/{{MESSAGE}}/, secure(message));
  }

  function secure(str){
    const decoder = document.createElement('div')
    decoder.innerHTML = str;
    return decoder.textContent
  }

  function submit(){
    hide(letterTemplate);
  }

  function previewLetter(){
    SvelteDialog.show(PreviewDialog, { letter: parseTemplate(letterTemplate, bidData), mode: 'PREVIEW'})
      .catch( noop )
  }
</script>


<ClosableModal close="{cancel}" width="700px">
  <div slot="heading">
    <div class="ComponentTitle">Send "No Thank You" Letter{multipleBidsTextSuffix}</div>
  </div>

  {#await viewPromise}
    <div class="ModalBody">
      <Spinner />
    </div>

    <div class="ModalFooter">
      <div>
        <button class="rbButton" disabled on:click="{previewLetter}">Preview</button>
      </div>

      <div>
        <button class="rbButton" on:click="{cancel}">Cancel</button>
        <button class="rbButton mainButton" disabled on:click="{submit}">Send</button>
      </div>
    </div>

  {:then data}
    <div class="ModalBody">
      <div class="ColumnTitle">
        Please provide hotel{multipleBidsTextSuffix} a reason for No Thank You
      </div>

      <div class="ReasonsList">
        {#each reasons as reason, index}
          <div class="Reason"
               data-icon-before="check_box_outline_blank"
               class:Selected={selectedReasons.indexOf(index) !== -1}
               on:click={() => toggleSelection(index)}>
            {@html reason}
          </div>
        {/each}
      </div>

      <div class="InputContainer">
        <label for="own">Write your own:</label>
        <input type="text" id="own" class="Input" maxlength="200" bind:value={ownReason} />
      </div>

      <div class="InputContainer">
        <label for="message">Your message to hotel{multipleBidsTextSuffix}:</label>
        <textarea id="message" class="Input" maxlength="1000" bind:value={message} />
      </div>
    </div>

    <div class="ModalFooter">
      <div>
        <button class="rbButton" on:click="{previewLetter}">Preview</button>
      </div>

      <div>
        <button class="rbButton" on:click="{cancel}">Cancel</button>
        <button class="rbButton mainButton"
                disabled="{selectedReasons.length || ownReason ? null : 'disabled'}"
                on:click="{submit}">Send</button>
      </div>
    </div>
  {/await}
</ClosableModal>

<style lang="stylus">.ComponentTitle {
  padding: 5px 15px;
  color: #455a64;
  font-weight: bold;
  font-size: 16px;
  line-height: 30px;
}
.ModalBody {
  max-height: 60vh;
  padding: 15px 0;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  font-size: 14px;
  color: #455a64;
}
.ModalFooter {
  height: 50px;
  padding: 15px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.ColumnTitle {
  flex: 0 0 auto;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 20px;
  padding: 0 15px;
}
.ReasonsList {
  flex: 1 1 auto;
  overflow-y: auto;
}
.Reason {
  padding: 0 30px;
  align-items: flex-start;
  cursor: pointer;
  margin-bottom: 14px;
  line-height: 24px;
}
.Reason:hover {
  color: #263238;
}
.Reason:before {
  padding-right: 10px;
}
.Reason.Selected:before {
  content: 'check_box';
  color: #00a99d;
}
.InputContainer {
  padding: 0 30px;
}
label {
  display: block;
  font-size: 13px;
}
.Input {
  font-family: "Noto Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #455a64;
  line-height: 20px;
  border: 1px solid #cfd8dc;
  border-radius: 3px;
  width: 100%;
  padding: 0 5px;
  min-height: 30px;
  margin-bottom: 15px;
}
.Input:focus {
  border-color: #00a99d;
}
</style>
