import {checkAndExecute, checkAndExecuteToActionReport} from 'root/api/helpers';

export default function HotelRfpBidBenchmarkRateAPI(api) {
  Object.assign(api, {

    getHotelRfpBidBenchmarkRate: bidId => checkAndExecute(
      () => bidId,
      () => api.server.get(`rfps/hotel/bids/benchmark-rate/${bidId}`)),


    setHotelRfpBidBenchmarkRate: (bidId, data) => checkAndExecuteToActionReport(
      () => bidId,
      () => api.server.put(`rfps/hotel/bids/benchmark-rate/${bidId}`, data)),
  });
}
