import noop from 'lodash/noop';
import ResendNegotiationRequestsDialog from './ResendNegotiationRequestsDialog.svelte'

import {NEGOTIATION_SENT, statusMatches} from 'rfp/bid-manager/main/core/actions/action/status';
import {SvelteDialog} from 'root/v-app/rbServices';

export function resendNegotiationRequests(actionResultHandler){
  return function (selectedBidsReport) {
    return {
      label: `Resend Negotiation Request${selectedBidsReport.bids.length === 1 ? '' : 's'}`,
      icon: 'drive_folder_upload',
      isAvailable: () =>  statusMatches(selectedBidsReport.statuses, [NEGOTIATION_SENT]),
      action: () => {
        SvelteDialog
          .show(ResendNegotiationRequestsDialog, {
            bids: selectedBidsReport.bids,
            actionResultHandler
          })
          .catch(noop);
      }
    };
  };
}
