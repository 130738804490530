<script>
  import ClosableModal from 'root/services/modals/Closable.modal.svelte';
  import {mainAPI} from 'root/angular-injector-provider';

  export let cancel, hide, bids = [];

  let negotiatedBids, isSingleBid, state = 0;

  $: isSingleBid = bids.length === 1
  $: negotiatedBids = bids.map(bid => ({bid, response: undefined, state: 'WORKING', status: 'Waiting...'}));

  async function resendRequests() {
    state = 1;
    for (let i = 0, l = negotiatedBids.length; i < l; i++) {
      const nb = negotiatedBids[i];
      try {
        negotiatedBids[i] = {...nb, status: 'Sending...'};
        // eslint-disable-next-line no-await-in-loop
        await mainAPI().hotelRfpNegotiationResendRequest(nb.bid._id);
        negotiatedBids[i] = {...nb, status: 'Sent!', state: 'DONE'};
      } catch (err) {
        console.error(err)
        const msg = getErrorMessage(err);
        negotiatedBids[i] = {
          ...nb,
          status: `ERROR - ${msg}`,
          state: 'ERROR'
        };
      }
    }

    state = 2;

    function getErrorMessage(err) {
      try {
        return err.data.message;
      } catch (e) {
        return 'Sending failed!';
      }
    }
  }
</script>

<ClosableModal close="{state === 1? false: cancel}" width="500px">
  <div slot="heading">
    <div class="Header">Resend Negotiation Request{isSingleBid? '': 's'}</div>
  </div>

  {#if state === 0}
    <div class="ContentHtml">
      {#if isSingleBid}
        Do you want to resend negotiation request?
      {:else}
        Do you want to resend {bids.length} negotiation requests?
      {/if}
    </div>

    <div class="Actions">
      <button type="button" class="aBtn atLight asLarge asMain" on:click={resendRequests}>Yes</button>
      <button type="button" class="aBtn atLight asLarge" on:click={cancel}>No</button>
    </div>

  {:else}
    <div class="Content">
      {#each negotiatedBids as negotiatedBid}
        <div class="Row">
          <div class="ellipsis">
            <b>{negotiatedBid.bid.supplier.company.name}</b>, <small>{negotiatedBid.bid.supplier.company.location.fullAddress}</small>
          </div>
          <div class="Result"
               class:Error={negotiatedBid.state === 'ERROR'}
               class:Done={negotiatedBid.state === 'DONE'}
          >{negotiatedBid.status}</div>
        </div>
      {/each}
    </div>

    <div class="Actions">
      <button type="button" class="aBtn atLight asLarge asMain" on:click={hide} disabled="{state < 2}">Close</button>
    </div>
  {/if}
</ClosableModal>

<style lang="stylus">.Header {
  padding: 9px 13px 0;
  color: #37474f;
  font-size: 16px;
  font-weight: bold;
}
.Content,
.ContentHtml {
  padding: 0 13px;
  color: #546e7a;
  font-size: 13px;
  margin: 10px 0 20px;
  max-height: 400px;
  overflow: auto;
}
.ContentHtml :global(div) {
  margin: 20px 0;
}
.Actions {
  padding: 0 18px 21px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.Row {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  margin: 5px 0;
}
.Result {
  flex: 0 0 90px;
  text-align: right;
}
.Error {
  color: #d75539;
}
.Done {
  color: #00a99d;
}
</style>
