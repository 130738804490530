import {checkAndExecute} from 'root/api/helpers';

export default function HotelRfpExportAPI(api) {

  function exportDataWithAutoSave(rfpId, exportData){
    return api.server.postAndAutoSaveFile(`rfps/hotel/${rfpId}/export`, exportData)
  }

  function exportRfpsDataWithAutoSave(exportData){
    return api.server.postAndAutoSaveFile(`rfps/hotel/rfps/export`, exportData)
  }

  Object.assign(api, {

    hotelRfpExportTravelDestinations: rfpId => checkAndExecute(
      () => rfpId,
      () => exportDataWithAutoSave(rfpId, {name: 'HOTEL_RFP:TRAVEL_DESTINATIONS:EXCEL'})),

    exportRfpPackage: rfpId => checkAndExecute(
      () => rfpId,
      () => exportRfpsDataWithAutoSave({name: 'HOTEL_RFP:RFP_PACKAGE:EXCEL', rfps: [rfpId]})),

    exportRfpPackageAsPdf: rfpId => checkAndExecute(
      () => rfpId,
      () => api.server.getAndAutoSaveFile(`rfps/hotel/rfps/${rfpId}/rfp-package/pdf`)),

    exportRfpsFinalAgreements: rfpsIds => checkAndExecute(
      () => rfpsIds,
      () => exportRfpsDataWithAutoSave({name: 'HOTEL_RFP:RFP_FINAL_AGREEMENTS:CSV', rfps: rfpsIds})),

    exportOptionsTravelHotelAuditForm: rfpsIds => checkAndExecute(
      () => rfpsIds,
      () => exportRfpsDataWithAutoSave({name: 'HOTEL_RFP:RFP_HOTEL_AUDIT_FORM:CSV', rfps: rfpsIds})),

    exportAllClientsBidsForProgramYear: programYear => checkAndExecute(
      () => programYear,
      () => exportRfpsDataWithAutoSave({name: 'HOTEL_RFP:ALL_CLIENTS_FOR_YEAR:CSV', rfps: [], properties: {programYear}})),

    exportNegotiationSavingsAllClientsForProgramYear: programYear => checkAndExecute(
      () => programYear,
      () => exportRfpsDataWithAutoSave({name: 'HOTEL_RFP:NEG_SAVINGS_ALL_CLIENTS_FOR_YEAR:CSV', rfps: [], properties: {programYear}})),

  })
}
