/**
 * Stores raw view from http response
 */


import {writable} from 'svelte/store';
import {mainAPI, BidManagerRedirectService} from 'root/angular-injector-provider';

const MOST_RECENT_CONFIG = {group: '', sort: {'bid.status': -1}};

const store = writable({}, () => () => {
  store.set({});
});

export default store;

export function initialize(config){
  return loadView(config)
    .then( rView => {
      tryToLoadRfpIdForTheChainPortal(config, rView.data)
      const columns = config.isChainPortal? config.formatColumns(rView.data.columns): rView.data.columns;
      store.set({
        ...rView.data,
        columns,
        rfpType: 'HOTEL',
        ...(config.mostRecent ? MOST_RECENT_CONFIG : {}),
        initialized: true})
      return handleHotelGroupsRedirect(config, rView.data);
    });
}

function loadView(config){
  return loadBidManagerViewWithRfpId(config)
    .catch(() => loadBidManagerViewWithViewId(config))
    .catch(() => loadBidManagerViewForChainPortal(config))
    .catch(() => redirectToDefaultView(config))
}

function loadBidManagerViewWithRfpId(config){
  return config.rfpId ? mainAPI().getBidManagerViewWithRfpId(config.rfpId) : Promise.reject(config);
}

function loadBidManagerViewWithViewId(config){
  return config.viewId ? mainAPI().getBidManagerViewWithViewId(config.viewId) : Promise.reject(config);
}

function loadBidManagerViewForChainPortal(config) {
  return config.isChainPortal? mainAPI().getBidManagerViewWithViewId('default'): Promise.reject(config);
}

function redirectToDefaultView(){
  BidManagerRedirectService().toDefaultView();
  return Promise.reject('No available Id')
}

async function handleHotelGroupsRedirect(config, view) {
  if(config.isChainPortal) {
    return view;
  } else {
    const redirected = await BidManagerRedirectService().toChainPortalFromBidManagerView(view, config.negotiations);
    return redirected? Promise.reject(view): view
  }
}

function tryToLoadRfpIdForTheChainPortal(config, viewData){
  if(!config.isChainPortal || !config.chainPortalConfig) return;
  const rfpGroup = config.chainPortalConfig.groups.find(g => g.group === 'RFP');
  const rfpId = rfpGroup? (rfpGroup.value || undefined): undefined
  if(rfpId) {
    viewData.rfpId = rfpId;
  }
}
