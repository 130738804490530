import { checkAndExecute } from 'root/api/helpers';

export default function HotelRfpBidAPI(api) {
  api.rateManager = {

    listBids: rfpId => checkAndExecute(
      () => rfpId,
      () => api.server.get(`rate-manager/query`, {rfp: rfpId})
    ),

    setAcceptedRates: (requests = [], resendFinalAgreements = false, setBidsStatusTo) => checkAndExecute(
      () => requests && requests.length,
      () => api.server.post(`rate-manager/set-accepted-rates`, {requests, resendFinalAgreements, setBidsStatusTo})
    ),

    resendFinalAgreements: bidsIds => checkAndExecute(
      () => bidsIds && bidsIds.length,
      () => api.server.post(`rate-manager/resend-final-agreements`, {bidsIds})
    ),

    sendFinalAgreements: bidsIds => checkAndExecute(
      () => bidsIds && bidsIds.length,
      () => api.server.post(`rate-manager/send-final-agreements`, {bidsIds})
    ),

    setFinalAgreementPending: bidsIds => checkAndExecute(
      () => bidsIds && bidsIds.length,
      () => api.server.post(`rate-manager/set-final-agreement-pending`, {bidsIds})
    ),

    setNoThankYouPending: bidsIds => checkAndExecute(
      () => bidsIds && bidsIds.length,
      () => api.server.post(`rate-manager/set-no-thank-you-pending`, {bidsIds})
    ),

    sendNoThankYou: (bidsIds, message) => checkAndExecute(
      () => bidsIds && bidsIds.length && message,
      () => api.server.post(`rate-manager/send-no-thank-you`, {bids: bidsIds, template: message})
    ),

    downloadTemplate: (data) => {
      return api.server.asCsvFileDownload(
        'rates_template',
        ['Bid ID', 'Status', 'Status Time', 'Hotel Name', 'Hotel Address', 'Destination Name', 'Currency', 'DYNAMIC', 'RT1_LRA_SGL', 'RT1_LRA_DBL', 'RT1_NLRA_SGL', 'RT1_NLRA_DBL', 'RT2_LRA_SGL', 'RT2_LRA_DBL', 'RT2_NLRA_SGL', 'RT2_NLRA_DBL', 'RT3_LRA_SGL', 'RT3_LRA_DBL', 'RT3_NLRA_SGL', 'RT3_NLRA_DBL',],
        data
      );
    }
  };
}
