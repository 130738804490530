/**
 * Created by DejanK on 8/10/2016.
 */
import {abstractState, simpleState, svelteState} from '../shared/tools/router-helper';
import {GUEST, USER} from 'auth/auth-roles';
import * as PublicBidPreview from 'root/public-bid/states/public-preview/public-bid-preview.ctrl';
import * as HotelRfpCompetitiveBidPublicPreview from 'root/public-bid/states/competitive-bids/public-preview/public-bid-preview.ctrl';
import * as BidManager from 'rfp/bid-manager/main/pages/manager/bid-manager.ctrl';

import * as BidPreview from 'root/rfp-hotel/main/bid/pages/preview/bid-preview.ctrl';
import * as CreateBidResponse from 'root/rfp-hotel/main/bid/pages/response/bid-response.ctrl';
import * as UpdateBidResponse from 'root/rfp-hotel/main/bid/pages/response/update-bid-response.ctrl';
import * as HotelRfpBidManualEntry from 'root/rfp-hotel/main/bid/pages/response/manual-entry/bid-manual-entry.ctrl';
import * as HotelRfpCompetitiveBidResponse from 'root/rfp-hotel/competitive-bids/response/comp-bid-response.ctrl';
import * as HotelRfpCompetitiveBidView from 'root/rfp-hotel/competitive-bids/view/competitive-bid-view.ctrl';
import * as ReviseBidResponse from 'root/rfp-hotel/main/bid/pages/response/revise/revise-bid-response.ctrl';
import * as BidPreviewCoverLetter from 'root/rfp-hotel/main/bid/pages/response/view-cover-letter.ctrl';
import * as RfpBidSpecifications from 'root/rfp-hotel/main/bid/pages/specifications/rfp-bid-specifications.ctrl';
import * as BidViewCoverLetter from 'root/rfp-hotel/main/bid/pages/cover-letter/view-cover-letter.ctrl';
import * as BidEditCoverLetter from 'root/rfp-hotel/main/bid/pages/cover-letter/edit-cover-letter.ctrl';
import * as BidViewQuestionnaire from 'root/rfp-hotel/main/bid/pages/questionnaire/view-questionnaire.ctrl';
import * as BidEditQuestionnaire from 'root/rfp-hotel/main/bid/pages/questionnaire/edit-questionnaire.ctrl';
import * as BidSendFinalAgreement from 'root/rfp-hotel/main/bid/pages/final-agreement/send-final-agreement.ctrl';
import * as BidEditFinalAgreement from 'root/rfp-hotel/main/bid/pages/final-agreement/edit-final-agreement.ctrl';
import * as BidViewFinalAgreement from 'root/rfp-hotel/main/bid/pages/final-agreement/view-final-agreement.ctrl';

import * as RfpPreview from 'root/rfp-hotel/main/rfp/pages/preview/rfp-preview.ctrl';
import * as RfpCompetitiveBids from 'root/rfp-hotel/main/rfp/pages/competitive-bids/competitive-bids.ctrl';
import * as RfpViewCoverLetter from 'root/rfp-hotel/main/rfp/pages/cover-letter/view-cover-letter.ctrl';
import * as RfpEditCoverLetter from 'root/rfp-hotel/main/rfp/pages/cover-letter/edit-cover-letter.ctrl';
import * as RfpViewNamCoverLetter from 'root/rfp-hotel/main/rfp/pages/nam-cover-letter/view-cover-letter.ctrl';
import * as RfpEditNamCoverLetter from 'root/rfp-hotel/main/rfp/pages/nam-cover-letter/edit-cover-letter.ctrl';
import * as RfpViewQuestionnaire from 'root/rfp-hotel/main/rfp/pages/questionnaire/view-questionnaire.ctrl';
import * as RfpEditQuestionnaire from 'root/rfp-hotel/main/rfp/pages/questionnaire/edit-questionnaire.ctrl';
import * as RfpDestinationMap from 'root/rfp-hotel/main/rfp/pages/destinations/map-destinations.ctrl';
import * as RfpViewFinalAgreement from 'root/rfp-hotel/main/rfp/pages/final-agreement/view-final-agreement.ctrl';
import * as RfpEditFinalAgreement from 'root/rfp-hotel/main/rfp/pages/final-agreement/edit-final-agreement.ctrl';

import TheDashboardPage from 'rfp/bid-manager/main/pages/dashboard/TheDashboardPage.svelte';

import {
  BID_MANAGER,
  HOTEL_RFP_RATE_MANAGER,
  HOTEL_RFP_BID_PREVIEW,
  HOTEL_RFP_BID_RESPONSE,
  HOTEL_RFP_BID_SPECIFICATIONS,
  HOTEL_RFP_BID_UPDATE_RESPONSE,
  HOTEL_RFP_COMPETITIVE_BIDS,
  CHAIN_PORTAL_STATE,
  HOTEL_RFP_COMPETITIVE_BIDS_PREVIEW,
  HOTEL_RFP_COMPETITIVE_BIDS_RESPONSE,
  HOTEL_RFP_COMPETITIVE_BIDS_VIEW, HOTEL_RFP_MANUAL_ENTRY,
  HOTEL_RFP_SPECIFICATIONS,
  NEGOTIATED_SAVINGS_REPORT,
  PAGE_RFP_MANAGER,
  SEND_FINAL_AGREEMENT_STATE,
  CHAIN_PORTAL_HOTEL_PROFILE_STATE,
  CHAIN_PORTAL_ALL_HOTELS_STATE,
  CHAIN_PORTAL_OVERDUE_BIDS_STATE,
  CHAIN_PORTAL_HOTEL_REQUEST,
  CHAIN_PORTAL_EDIT_HOTEL_REQUEST,
  CHAIN_PORTAL_CREATE_REQUEST_TO_ADD_HOTEL,
  DASHBOARD,
  CHAIN_PORTAL_UPLOAD_HOTELS_STATE,
  HOTEL_RFP_DESTINATION_ADD_HOTELS_BY_SABRE_CODE,
  HOTEL_RFP_DESTINATION_VIEW,
} from 'root/states';


export default States;


function States($stateProvider) {
  $stateProvider
    .state('publicBidLink',
      svelteState('/public/bids/{id}',
        () => import(/* webpackChunkName: "TheBidLinkPreviewPage" */ 'root/public-bid/states/bid-link/TheBidLinkPreviewPage.svelte')))
    .state('publicBidPreview', simpleState('/public-bid-preview?token,direct', PublicBidPreview.HTML, PublicBidPreview.Controller))
    .state('publicCompetitiveBidPreview',
      svelteState('/public/competitive/preview-bid/{token}',
        () => import( /* webpackChunkName: "TheCompetitiveBidLandingPage" */'root/public-bid/states/competitive-bids/landing-page/CompetitiveBidsLandingPage.svelte')))

    .state(HOTEL_RFP_COMPETITIVE_BIDS_PREVIEW, simpleState('/public/competitive/preview-bid/bid/{token}?direct', HotelRfpCompetitiveBidPublicPreview.HTML, HotelRfpCompetitiveBidPublicPreview.Controller))

    .state('publicCompetitiveBidDecline',  svelteState(
      '/public/competitive/decline-bid/:token',
      () => import( /* webpackChunkName: "TheCompetitiveBidDeclineBidPage" */'root/public-bid/states/competitive-bids/decline-to-bid/HotelRfpCompetitiveBidDeclineToBidPage.svelte')))

    .state(DASHBOARD, svelteState('/dashboard', () => TheDashboardPage))

    .state(BID_MANAGER, simpleState('/bid-manager/{viewId}?account&negotiations,rfp,most-recent', BidManager.HTML, BidManager.Controller))

    .state(HOTEL_RFP_RATE_MANAGER,
      svelteState('/rate-manager?rfp',
        () => import(/* webpackChunkName: "RateManagerPage" */ 'root/rfp/rate-manager/RateManagerPage.svelte')))


    .state(PAGE_RFP_MANAGER, svelteState(
      '/rfp-manager',
      () => import( /* webpackChunkName: "TheRfpManager" */'rfp/rfp-manager/main/pages/RfpManager.svelte')))

    .state('rfps', abstractState('/rfps'))


    .state('hotelRfp', abstractState('/hotel-rfp', USER))

    .state('hotelRfp.rfp', abstractState('/rfps/{rfpId}'))

    .state('hotelRfp.rfp.reports', abstractState('/reports'))
    .state(NEGOTIATED_SAVINGS_REPORT,
        svelteState('/negotiated-savings?steps,currency,sorter',
          () => import(/* webpackChunkName: "TheNegotiatedSavingsReportsPage" */ 'rfp/reports/negotiated-savings/TheNegotiatedSavingsReportsPage.svelte')))

    .state('hotelRfp.rfp.preview', simpleState('/preview', RfpPreview.HTML, RfpPreview.Controller, null, isNam(false)))
    .state('hotelRfp.rfp.namPreview', simpleState('/nam-preview', RfpPreview.HTML, RfpPreview.Controller, null, isNam(true)))

    .state(HOTEL_RFP_SPECIFICATIONS,
      svelteState('/specifications', () => import(/* webpackChunkName: "RfpSpecificationsPage" */ 'root/rfp-hotel/main/rfp/pages/specifications/RfpSpecificationsPage.svelte')))
    .state(HOTEL_RFP_COMPETITIVE_BIDS, simpleState('/competitive-bids', RfpCompetitiveBids.HTML, RfpCompetitiveBids.Controller))

    .state('hotelRfp.rfp.coverLetter', abstractState('/cover-letter'))
    .state('hotelRfp.rfp.coverLetter.view', simpleState('/view', RfpViewCoverLetter.HTML, RfpViewCoverLetter.Controller))
    .state('hotelRfp.rfp.coverLetter.edit', simpleState('/edit', RfpEditCoverLetter.HTML, RfpEditCoverLetter.Controller))

    .state('hotelRfp.rfp.namCoverLetter', abstractState('/nam-cover-letter'))
    .state('hotelRfp.rfp.namCoverLetter.view', simpleState('/view', RfpViewNamCoverLetter.HTML, RfpViewNamCoverLetter.Controller))
    .state('hotelRfp.rfp.namCoverLetter.edit', simpleState('/edit', RfpEditNamCoverLetter.HTML, RfpEditNamCoverLetter.Controller))

    .state('hotelRfp.rfp.questionnaire', abstractState('/questionnaire'))
    .state('hotelRfp.rfp.questionnaire.view', simpleState('/view', RfpViewQuestionnaire.HTML, RfpViewQuestionnaire.Controller))
    .state('hotelRfp.rfp.questionnaire.edit', simpleState('/edit', RfpEditQuestionnaire.HTML, RfpEditQuestionnaire.Controller))

    .state('hotelRfp.rfp.destinations', abstractState('/travel-destinations'))
    .state(HOTEL_RFP_DESTINATION_VIEW,
      svelteState('/view',
        () => import(/* webpackChunkName: "RfpViewDestinationsSvelte" */ 'root/rfp-hotel/main/rfp/pages/destinations/view-destinations/ViewDestinations.page.svelte')))


    .state('hotelRfp.rfp.destinations.map', simpleState('/{destinationId}/map?from', RfpDestinationMap.HTML, RfpDestinationMap.Controller))
    .state('hotelRfp.rfp.destinations.list', simpleState('/list-view', '<rbs-header /><rbv-ng-rfp-travel-destinations-list/>'))
    .state(HOTEL_RFP_DESTINATION_ADD_HOTELS_BY_SABRE_CODE, svelteState('/{destinationId}/add-by-sabre',
      () => import(/* webpackChunkName: "TheAddHotelsBySabreCodePage" */ 'root/rfp-hotel/main/rfp/pages/destinations/add-hotels-by-sabre-code/TheAddHotelsBySabreCodePage.svelte')))

    .state('hotelRfp.rfp.finalAgreement', abstractState('/final-agreement'))
    .state('hotelRfp.rfp.finalAgreement.view', simpleState('/view', RfpViewFinalAgreement.HTML, RfpViewFinalAgreement.Controller))
    .state('hotelRfp.rfp.finalAgreement.edit', simpleState('/edit', RfpEditFinalAgreement.HTML, RfpEditFinalAgreement.Controller))

    .state('hotelRfp.bids', abstractState('/group'))
    .state(CHAIN_PORTAL_STATE,
      svelteState('/portal?steps,sorter,page,chain,negotiations,assignments,hidePrior',
        () => import(/* webpackChunkName: "TheChainPortalsPage" */ 'rfp/reports/chain-portal/TheChainPortalsPage.svelte')))
    .state(CHAIN_PORTAL_HOTEL_PROFILE_STATE,
      svelteState('/profile/{id}?section',
        () => import(/* webpackChunkName: "TheChainPortalHotelProfile" */ 'rfp/reports/chain-portal/profile/HotelProfile.page.svelte')))

    .state(CHAIN_PORTAL_ALL_HOTELS_STATE,
      svelteState('/hotels?requests',
        () => import(/* webpackChunkName: "TheChainPortalHotels" */ 'rfp/reports/chain-portal/hotels/Hotels.page.svelte')))


    .state(CHAIN_PORTAL_HOTEL_REQUEST, abstractState('/chain-portal/hotels'))
    .state(CHAIN_PORTAL_EDIT_HOTEL_REQUEST,
      svelteState('/edit-hotel-request?id',
        () => import(/* webpackChunkName: "TheChainPortalEditHotelRequest" */ 'rfp/reports/chain-portal/hotels/EditHotelRequest.page.svelte')))
    .state(CHAIN_PORTAL_CREATE_REQUEST_TO_ADD_HOTEL,
      svelteState('/add-hotel-request/create',
        () => import(/* webpackChunkName: "TheChainPortalCreateAddHotelRequest" */ 'rfp/reports/chain-portal/hotels/requests/CreateAddHotelRequest.svelte')))

    .state(CHAIN_PORTAL_OVERDUE_BIDS_STATE,
      svelteState('/overdue',
        () => import(/* webpackChunkName: "TheChainPortalOverDueBids" */ 'rfp/reports/chain-portal/bid-manager/OverDueBids.svelte')))

    .state(CHAIN_PORTAL_UPLOAD_HOTELS_STATE,
      svelteState('/upload',
        () => import(/* webpackChunkName: "TheChainPortalUploads" */ 'rfp/reports/chain-portal/hotels/upload/Uploads.page.svelte')))

    .state('hotelRfp.bid', abstractState('/bids/{bidId}'), USER)

    .state(HOTEL_RFP_BID_PREVIEW, simpleState('/preview', BidPreview.HTML, BidPreview.Controller))
    .state(HOTEL_RFP_BID_RESPONSE, simpleState('/response', CreateBidResponse.HTML, CreateBidResponse.Controller))
    .state(HOTEL_RFP_BID_UPDATE_RESPONSE, simpleState('/update-response', UpdateBidResponse.HTML, UpdateBidResponse.Controller))
    .state(HOTEL_RFP_COMPETITIVE_BIDS_RESPONSE, simpleState('/competitive-bids/response', HotelRfpCompetitiveBidResponse.HTML, HotelRfpCompetitiveBidResponse.Controller))
    .state(HOTEL_RFP_COMPETITIVE_BIDS_VIEW, simpleState('/competitive-bids/view', HotelRfpCompetitiveBidView.HTML, HotelRfpCompetitiveBidView.Controller))
    .state('hotelRfp.bid.reviseResponse', simpleState('/revise-response', ReviseBidResponse.HTML, ReviseBidResponse.Controller))
    .state(HOTEL_RFP_MANUAL_ENTRY, simpleState('/manual-entry', HotelRfpBidManualEntry.HTML, HotelRfpBidManualEntry.Controller))

    .state(HOTEL_RFP_BID_SPECIFICATIONS, simpleState('/specifications', RfpBidSpecifications.HTML, RfpBidSpecifications.Controller))

    .state('hotelRfp.bid.coverLetter', abstractState('/cover-letter'))
    .state('hotelRfp.bid.coverLetter.preview', simpleState('/letterview?attachments', BidPreviewCoverLetter.HTML, BidPreviewCoverLetter.Controller))
    .state('hotelRfp.bid.coverLetter.view', simpleState('/view', BidViewCoverLetter.HTML, BidViewCoverLetter.Controller))
    .state('hotelRfp.bid.coverLetter.edit', simpleState('/edit', BidEditCoverLetter.HTML, BidEditCoverLetter.Controller))

    .state('hotelRfp.bid.questionnaire', abstractState('/questionnaire'))
    .state('hotelRfp.bid.questionnaire.view', simpleState('/view', BidViewQuestionnaire.HTML, BidViewQuestionnaire.Controller))
    .state('hotelRfp.bid.questionnaire.edit', simpleState('/edit', BidEditQuestionnaire.HTML, BidEditQuestionnaire.Controller))

    .state('hotelRfp.bid.finalAgreement', abstractState('/final-agreement'))
    .state(SEND_FINAL_AGREEMENT_STATE, simpleState('/send', BidSendFinalAgreement.HTML, BidSendFinalAgreement.Controller))
    .state('hotelRfp.bid.finalAgreement.edit', simpleState('/edit', BidEditFinalAgreement.HTML, BidEditFinalAgreement.Controller))
    .state('hotelRfp.bid.finalAgreement.view', simpleState('/view?token', BidViewFinalAgreement.HTML, BidViewFinalAgreement.Controller, [USER, GUEST]))
}

function isNam(v) { return { resolve: { isNam: () => v } } }
