import Vue from 'vue';
import noop from 'lodash/noop';

export default {
  template: '<div></div>',
  bindings: {
    bid: '<',
    options: '<',
    attachments: '<',
    manageAttachments: '<'
  },
  controller: Ctrl,
}

Ctrl.$inject = ['$element', 'CurrentUser'];
function Ctrl($element, currentUser){
  const
    vm = this;


  let
    vueComponent = null,
    props = {};

  Object.assign(this, {

    $onInit () {
      import( /* webpackChunkName: 'TheHotelRateQuickBidForm' */ 'vRoot/rfp-hotel/bid/rate-quick/TheRateQuickQuestionnaireForm.vue')
        .then( async (imported) => {
          const user = await currentUser.get();
          props = {
            bid: vm.bid,
            user,
            options: vm.options,
            attachments: vm.attachments,
            manageAttachments: vm.manageAttachments
          }

          vueComponent = new Vue({
            el: $element[0],
            data: props,
            render ( h ) {
              return h( imported.default, { props });
            },
          });
        })
        .catch( noop );
    },

    $onDestroy () {
      if(vueComponent) {
        vueComponent.$destroy();
      }
    },

    $onChanges (changes) {
      if(changes.attachments){
        Vue.set(props, 'attachments', [...changes.attachments.currentValue]);
      }
    }
  });
}
