/**
 * Created by DejanK on 4/13/2017.
 */
import HTML from './standard-bid-response.html'

import {get, noop} from 'lodash'

export default {
  template: HTML,
  bindings: {
    bid: '<',
    options: '<',
    attachments: '<',
    manageAttachments: '<'
  },
  controller: CreateBidResponseController,
}

CreateBidResponseController.$inject = ['NotificationUtils', '$scope'];
function CreateBidResponseController(notificationUtils, $scope){
  const vm = this;
  let areQuestionsTouched, mounted, response;

  vm.isDraftSaved = false;
  vm.onAnswersChanged = onAnswersChanged;
  vm.gotoQuestion = gotoQuestion;
  vm.close = close;
  vm.save = save;
  vm.saveDraft = saveDraft;

  this.$onChanges = function( changes ) {
    if(!mounted) {
      vm.bid = get(changes, 'bid.currentValue');
      vm.bidId = get(vm.bid, 'id');
      vm.savedResponseSource = vm.options.savedResponseSource || 'responseDraft';
      vm.questionnaire = get(vm.bid, 'questionnaire');
      response = get(vm.questionnaire, vm.savedResponseSource);
      if(vm.options.onMount) vm.options.onMount();
      mounted = true;
    }
  };

  function onAnswersChanged( event ){
    response = event.response ;
    vm.isDraftSaved = false;

    if(response.response.touched) touchQuestions();
    if( areQuestionsTouched ) checkErrors();
  }

  function touchQuestions(){
    if(!areQuestionsTouched) {
      $scope.$broadcast('vue-event', {event: 'questionnaireQuestion.touch'});
      areQuestionsTouched = true;
      checkErrors();
    }
  }

  function checkErrors(){
    const MAX_ERRORS_SHOWN = 10;
    vm.errors = response ? Object.values(response.errors).map(v => v[0]).sort((a, b) => a.ord - b.ord) : [];
    vm.hiddenErrorsCount = vm.errors.length - MAX_ERRORS_SHOWN;

    if( vm.hiddenErrorsCount > 0 ) {
      vm.errors.length = MAX_ERRORS_SHOWN;
    }
  }

  function close(){
    vm.options.buttons.cancel.action();
  }

  function gotoQuestion(id){
    const div = document.getElementsByClassName('DocumentViewContentContainer')[0],
      el = document.getElementById(id);

    if(el){
      div.scrollTop += el.getBoundingClientRect().top - 205;
    }
  }

  function getAnswers(){
    return response ? response.response.answers : {};
  }

  function saveDraft(){
    touchQuestions();
    if( !vm.isDraftSaved ){
      vm.isDraftSaved = true;
      vm.options.buttons.draft.action(getAnswers())
        .catch( () => {
          vm.isDraftSaved = false;
          $scope.$applyAsync();
        })
    }
  }


  function save() {
    touchQuestions();
    vm.options.buttons.submit.action(response)
      .catch(noop);
  }
}
